<script setup>
import { computed, onMounted, onUnmounted, ref } from "vue";
import { heightToTop } from "@/utils";
import { useRoute, useRouter } from "vue-router";

const navs = [
  { key: "1", na: "首页", link: "/", id: "ecHome" },
  { key: "2", na: "产品与服务", link: "/", id: "ecHomeService" },
  // { key: "3", na: "新闻中心", link: "/news", id: "ecNews" },
  { key: "4", na: "关于我们", link: "/about", id: "ecAbout" },
];

const route = useRoute();
const router = useRouter();

const active = ref(navs[0].key);
const navRef = ref(null);
const activeWidth = ref(0);
const activeLeft = ref(45);
const scrollTop = ref(0);

const activeIndex = computed(() =>
  navs.findIndex((it) => it.key === active.value)
);

const onNav = ({ key, link, id }) => {
  active.value = key;
  router.push(link);
  if (id) {
    setTimeout(() => {
      const target = document.getElementById(id);
      document.documentElement.scrollTop = heightToTop(target) - 100;
    }, 0);
  }
};

const onScroll = () => {
  const sTop = document.body.scrollTop + document.documentElement.scrollTop;
  scrollTop.value = sTop;
  if (!navRef.value) return;
  if (sTop > 106) {
    navRef.value.classList.add("fixed");
    return;
  }
  navRef.value.classList.remove("fixed");
};

const onMenu = (item) => {
  if (item.top !== void 0) {
    document.documentElement.scrollTop = item.top;
    return;
  }
  if (item.id) {
    const ele = document.getElementById(item.id);
    if (!ele) return;
    const height = heightToTop(ele);
    document.documentElement.scrollTop = height - 129;
  }
};

const onMouseOver = (el) => {
  const styles = getComputedStyle(el);
  const width = parseFloat(styles.width) || 0;
  activeWidth.value = width - 90;
  activeLeft.value = el.offsetLeft + 45;
};

const onMouseLeave = () => {
  const current = document.querySelector(
    `.ec-main-header-nav-item-${activeIndex.value}`
  );
  onMouseOver(current);
};

onMounted(() => {
  onScroll();
  document.addEventListener("scroll", onScroll);

  const currentPath = window.location.pathname;
  let currentIndex = navs.findIndex((it) => {
    if (it.link === "/") return it.link === currentPath;
    return new RegExp("^" + it.link).test(currentPath);
  });
  if (currentIndex < 0) currentIndex = 0;
  const current = document.querySelector(
    `.ec-main-header-nav-item-${currentIndex}`
  );
  onMouseOver(current);
});

onUnmounted(() => {
  document.removeEventListener("scroll", onScroll);
});
</script>

<template>
  <header
    class="ec-main-header"
    :class="`route${route.path.replace(/\//g, '-')}`"
  >
    <nav class="ec-main-header-nav" ref="navRef">
      <div class="ec-main-header-nav-inner ec-container">
        <h1 title="益点关爱" class="ec-main-header-logo">
          <img
            src="@/assets/images/logo.png"
            alt="益点关爱"
            v-if="
              scrollTop > 106 ||
              !['^/news', '^/about'].some((it) =>
                new RegExp(it).test(route.path)
              )
            "
          />
          <img src="@/assets/images/logo-white.png" alt="益点关爱" v-else />
        </h1>
        <ul class="ec-main-header-nav-list" @mouseleave="() => onMouseLeave()">
          <li
            class="ec-main-header-nav-item"
            :class="[
              active === item.key ? 'active' : '',
              `ec-main-header-nav-item-${index}`,
            ]"
            v-for="(item, index) in navs"
            :key="item.key"
            @click="onMenu(item)"
            @mouseover="(e) => onMouseOver(e.currentTarget)"
          >
            <a href="javascript: void(0);" @click="onNav(item)">
              {{ item.na }}
            </a>
          </li>
          <li
            class="ec-main-header-nav-pointer"
            :style="{
              width: `${activeWidth}px`,
              transform: `translateX(${activeLeft}px)`,
            }"
          />
        </ul>
      </div>
    </nav>
  </header>
</template>

<style lang="less" scoped>
.ec-main-header {
  width: 100%;
  height: 106px;
  min-width: max-content;
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;

  &-logo {
    width: 162px;
    height: 40px;

    img {
      display: block;
      outline: none;
      border: 0;
      height: 100%;
    }
  }

  &-nav {
    height: 100%;

    &.fixed {
      box-shadow: 0 2px 4px 0 rgba(104, 119, 174, 0.08);
      background: rgba(255, 255, 255, 1);

      .ec-main-header-nav-item a {
        color: @ec-color-primary;
      }
      .ec-main-header-nav-pointer {
        background: @ec-color-primary;
      }
    }
  }
  &-nav-inner {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
  }

  &-nav-list {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
  }
  &-nav-item {
    height: 100%;
    padding: 0 50px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      font-size: 16px;
      font-weight: normal;
      color: #ffffff;
    }
  }
  &-nav-pointer {
    display: block;
    height: 4px;
    border-radius: 2px;
    background: #ffffff;
    position: absolute;
    z-index: 1;
    left: 0;
    bottom: 27px;
    transition: all 0.3s ease-in-out;
  }
}
</style>
