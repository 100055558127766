<script setup>
import { computed, onMounted, reactive, ref, toRaw } from "vue";
import { showToast } from "@/utils";
import EcButton from "@/components/Button.vue";
import { getImageCode, submitContact } from "@/apis";
import { useStore } from "vuex";

const formRef = ref();
const formState = reactive({
  company: "",
  name: "",
  email: "",
  phone: "",
  content: "",
  code: "",
});
const rules = {
  company: [
    {
      required: true,
      message: "请输入您的公司",
      trigger: "blur",
    },
    {
      min: 1,
      max: 100,
      message: "公司名称长度不超过100个字符",
      trigger: "blur",
    },
  ],
  name: [
    {
      required: true,
      message: "请输入您的姓名",
      trigger: "blur",
    },
    {
      min: 1,
      max: 10,
      message: "姓名长度不超过10个字符",
      trigger: "blur",
    },
  ],
  email: [
    {
      required: true,
      message: "请输入您的邮箱",
      trigger: "blur",
    },
    {
      min: 1,
      max: 100,
      message: "邮箱不超过100个字符",
      trigger: "blur",
    },
  ],
  phone: [
    {
      required: true,
      message: "请输入您的手机号",
      trigger: "blur",
    },
    {
      min: 11,
      max: 11,
      message: "手机号为11个字符",
      trigger: "blur",
    },
  ],
  content: [
    {
      required: true,
      message: "请输入您的留言内容",
      trigger: "blur",
    },
    {
      min: 1,
      max: 500,
      message: "留言内容长度为1-500个字符",
      trigger: "blur",
    },
  ],
  code: [
    {
      required: true,
      message: "请输入验证码",
      trigger: "blur",
    },
    {
      min: 4,
      max: 4,
      message: "验证码为4个字符",
      trigger: "blur",
    },
  ],
};
const codeImg = ref();
const loading = ref(false);
const store = useStore();

const appConfig = computed(() => store.state.config);

const onSubmit = () => {
  if (loading.value) return;
  loading.value = true;
  formRef.value
    .validate()
    .then(async () => {
      const data = toRaw(formState);
      const res = await submitContact({
        appId: appConfig.value.appId,
        name: data.name,
        company: data.company,
        contact: data.phone,
        remark: data.content,
        code: data.code,
      });
      if (res.code !== 0) {
        showToast(res);
        loading.value = false;
        return;
      }
      await showToast({ code: 0, msg: "提交成功!" });
      loading.value = false;
      document.documentElement.scrollTop = 0;
      window.history.go(0);
    })
    .catch((error) => {
      console.log("error", error);
      loading.value = false;
    });
};

// 获取验证码
const getCode = async () => {
  if (loading.value) return;
  loading.value = true;
  const res = await getImageCode({
    width: 126,
    height: 57,
    appId: appConfig.value.appId,
  });
  if (res.code !== 0) {
    showToast(res);
    loading.value = false;
    return;
  }
  codeImg.value = res.data;
  loading.value = false;
};

onMounted(() => {
  getCode();
});
</script>

<template>
  <div class="ec-footer" id="ecFooter">
    <div class="ec-footer-inner ec-container">
      <div class="ec-footer-left">
        <div class="ec-footer-card">
          <a-form
            ref="formRef"
            :model="formState"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <a-row class="ec-footer-form-row" :gutter="30">
              <a-col :span="12" class="ec-footer-form-col">
                <a-form-item ref="company" label="" name="company">
                  <a-input
                    autocomplete="off"
                    placeholder="您的公司"
                    v-model:value="formState.company"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="12" class="ec-footer-form-col">
                <a-form-item ref="name" label="" name="name">
                  <a-input
                    autocomplete="off"
                    placeholder="您的姓名"
                    v-model:value="formState.name"
                  />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row class="ec-footer-form-row" :gutter="30">
              <!--              <a-col :span="12" class="ec-footer-form-col">-->
              <!--                <a-form-item ref="email" label="" name="email">-->
              <!--                  <a-input-->
              <!--                    autocomplete="off"-->
              <!--                    placeholder="您的邮箱"-->
              <!--                    v-model:value="formState.email"-->
              <!--                  />-->
              <!--                </a-form-item>-->
              <!--              </a-col>-->
              <a-col :span="24" class="ec-footer-form-col">
                <a-form-item ref="phone" label="" name="phone">
                  <a-input
                    autocomplete="off"
                    placeholder="手机号（已加密）"
                    v-model:value="formState.phone"
                  />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row class="ec-footer-form-row">
              <a-col :span="24" class="ec-footer-form-col">
                <a-form-item ref="content" label="" name="content">
                  <a-textarea
                    autocomplete="off"
                    placeholder="您的需求..."
                    v-model:value="formState.content"
                  />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row class="ec-footer-form-row">
              <a-col :span="24" class="ec-footer-form-col">
                <a-form-item ref="code" label="" name="code" :autoLink="false">
                  <div class="ec-footer-input-addon">
                    <a-input
                      autocomplete="off"
                      placeholder="验证码"
                      v-model:value="formState.code"
                      @blur="
                        () => {
                          $refs.code.onFieldBlur();
                        }
                      "
                      @change="
                        () => {
                          $refs.code.onFieldChange();
                        }
                      "
                    />
                    <a
                      href="javascript: void(0);"
                      class="ec-footer-code"
                      @click="getCode"
                    >
                      <img :src="codeImg" alt="" />
                    </a>
                  </div>
                </a-form-item>
              </a-col>
            </a-row>

            <EcButton class="ec-footer-form-btn" @click="onSubmit"
              >提 交</EcButton
            >
          </a-form>
          <div class="ec-footer-card-pointer">
            <img src="@/assets/images/footer-illu.png" alt="" />
          </div>
        </div>
      </div>
      <div class="ec-footer-right">
        <h3 class="ec-footer-right-title">
          <p>企业福利 选益点关爱</p>
          <p>填写信息 免费试用</p>
        </h3>
        <div class="ec-footer-right-qrcode">
          <img src="@/assets/images/footerCode.png" alt="" />
          <p>益点关爱公众号</p>
        </div>
        <div class="ec-footer-right-copyright">
          Copyright &copy; 2019 益点关爱All rights reserved.
        </div>
        <div class="ec-footer-right-icp">
          <a
            class="ec-footer-right-icp-beian"
            href="https://beian.miit.gov.cn"
            target="_blank"
          >
            京ICP备2022034097号
          </a>
          <a
            class="ec-footer-right-icp-anbei"
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502051567"
          >
            <img src="@/assets/images/anbei-icon.png" />
            <p>京公网安备 11010502051567号</p>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less">
.ec-footer {
  background: url("@/assets/images/footer-bg.png") no-repeat left bottom / cover;
  padding-bottom: 65px;
  min-width: max-content;
  margin-top: 150px;
  padding-top: 91px;
  min-height: 724px;

  &-inner {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 196px;
  }

  &-left {
  }
  &-card {
    background: #ffffff;
    border: 1px solid #fff6ef;
    box-shadow: 10px 13px 25px 2px rgba(253, 167, 89, 0.13);
    border-radius: 20px;
    position: relative;
    padding: 53px;
    padding-bottom: 67px;
  }

  .ant-form {
    .ant-form-item {
      margin-bottom: 24px;
    }
    .ant-form-item-control {
      width: 100%;
      max-width: 100%;

      input {
        height: 59px;
        line-height: 59px;
        width: 100%;
        padding: 0 25px;
        font-size: 14px;
        font-weight: 400;
      }
      textarea {
        height: 109px;
        resize: none;
        padding: 12px 25px;
        font-size: 14px;
        font-weight: 400;
      }
    }
    .ant-form-item-explain {
      //opacity: 1;
      position: absolute;
      z-index: 1000;
      left: 0;
      bottom: -24px;
    }
  }

  &-input-addon {
    position: relative;

    :deep(input.ant-input) {
      padding-right: 153px !important;
    }

    .ec-footer-code {
      position: absolute;
      z-index: 1;
      right: 0;
      top: 1px;
      width: 128px;
      height: 100%;
    }
  }

  &-card-pointer {
    position: absolute;
    z-index: 1;
    width: 180px;
    height: 430px;
    bottom: 26px;
    right: -154px;

    img {
      width: 100%;
      height: 100%;
    }
  }
  &-form-row {
  }
  &-form-col {
  }
  &-form-btn {
    border-radius: 8px;
    width: 100%;
  }
  &-right {
  }
  &-right-title {
    font-size: 32px;
    font-weight: bold;
    color: #ffffff;
  }
  &-right-qrcode {
    width: 209px;
    height: 209px;
    margin-top: 81px;

    img {
      width: 100%;
      height: 100%;
    }

    p {
      font-size: 18px;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      margin-top: 15px;
    }
  }
  &-right-copyright {
    margin-top: 72px;
  }
  &-right-icp a,
  &-right-copyright {
    font-size: 14px;
    font-weight: 400;
    color: #fefefe;
    line-height: 43px;

    &:hover {
      color: #fefefe;
    }
  }
  &-right-icp {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
  }
  &-right-icp-beian {
  }
  &-right-icp-anbei {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
  }
}
</style>
