<template>
  <div class="ec-app">
    <Header />
    <div class="ec-app-content">
      <router-view />
    </div>
    <Footer />
  </div>
</template>

<script>
import AOS from "aos";
import { getScrollTop } from "@/utils";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "App",
  components: { Header, Footer },
  data() {
    return {
      scrollRef: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    onScroll() {
      this.$store.commit("setScrollTop", getScrollTop());
      // increase value up to 10, then refresh AOS
      this.scrollRef <= 10 ? this.scrollRef++ : AOS.refresh();
    },
  },
};
</script>

<style lang="less">
#app {
}
</style>
