<template>
  <div class="ec-home" id="ecHome">
    <section class="ec-home-header">
      <div class="ec-container">
        <div class="ec-home-header-left">
          <h2 class="ec-home-header-title">一站式全场景员工福利平台</h2>
          <h3 class="ec-home-header-title sub">
            <p>提升员工满意度</p>
            <p>节省员工成本最高达39%</p>
          </h3>
          <EcButton class="ec-home-header-btn" @click="onFree"
            >免费试用</EcButton
          >
        </div>
        <div class="ec-home-header-right"></div>
      </div>
    </section>
    <section class="ec-home-features">
      <div class="ec-container">
        <ul class="ec-home-features-card">
          <li class="ec-home-features-card-item">
            <img
              src="@/assets/images/home/icon01.png"
              alt=""
              class="ec-home-features-card-icon"
            />
            <p class="ec-home-features-card-text">弹性福利平台</p>
          </li>
          <li class="ec-home-features-card-item">
            <img
              src="@/assets/images/home/icon02.png"
              alt=""
              class="ec-home-features-card-icon"
            />
            <p class="ec-home-features-card-text">员工激励</p>
          </li>
          <li class="ec-home-features-card-item">
            <img
              src="@/assets/images/home/icon03.png"
              alt=""
              class="ec-home-features-card-icon"
            />
            <p class="ec-home-features-card-text">节日福利</p>
          </li>
        </ul>
      </div>
    </section>
    <section class="ec-home-content">
      <div class="ec-container">
        <h2 class="ec-home-content-title">定制福利方案</h2>
        <h3 class="ec-home-content-title sub">
          一站式全场景福利平台，统筹福利管理，覆盖多维需求，升级福利体验
        </h3>
        <Tabs />
      </div>
    </section>
    <section class="ec-home-service" id="ecHomeService">
      <div class="ec-container">
        <h2 class="ec-home-service-title">产品与服务</h2>
        <h3 class="ec-home-service-title sub">
          企业独立网购权限 低价好物企业专属
        </h3>
        <ul class="ec-home-service-list">
          <li class="ec-home-service-item">
            <img
              class="ec-home-service-item-icon"
              src="@/assets/images/home/product-icon01.png"
              alt=""
            />
            <h4 class="ec-home-service-item-title">弹性福利</h4>
            <p class="ec-home-service-item-article">
              平台全面整合垂直领域优质供应商，汇集知名品牌资源搭建自营福利商城，拓展品牌直连商城，同时兼容企业原有供应商，赋予员工高自由度福利选择，满足员工个性化需求，升级员工福利体验，提升企业福利效能。
            </p>
          </li>
          <li class="ec-home-service-item">
            <img
              class="ec-home-service-item-icon"
              src="@/assets/images/home/product-icon02.png"
              alt=""
            />
            <h4 class="ec-home-service-item-title">员工午餐/交通/通讯</h4>
            <p class="ec-home-service-item-article">
              企业统一发放，定期结算并提供数据报告。
              计入福利/教育培训科目，节约企业成本。 可兼容原有供应商，快速部署。
              形式与实质匹配，符合国家相关法规。
            </p>
          </li>
          <li class="ec-home-service-item">
            <img
              class="ec-home-service-item-icon"
              src="@/assets/images/home/product-icon03.png"
              alt=""
            />
            <h4 class="ec-home-service-item-title">员工满意度调研</h4>
            <p class="ec-home-service-item-article">
              帮助企业员工打造满意度调查方案,为了提高员工的工作积极性,完善公司各方面管理制度,通过不同维度进行综合分析，提高员工的满意度和工作效率。
            </p>
          </li>
          <li class="ec-home-service-item">
            <img
              class="ec-home-service-item-icon"
              src="@/assets/images/home/product-icon04.png"
              alt=""
            />
            <h4 class="ec-home-service-item-title">节日福利</h4>
            <p class="ec-home-service-item-article">
              产品涵盖端午，中秋，新年等传统节日，也提供妇女节，劳动节，儿童节，国庆节，生日福利，夏日福利，客户礼赠等福利及礼赠场景的多种解决方案。多档位套餐可以满足企业员工福利个性化需求，并提供卡券定制、平台定制、礼盒定制、套餐定制等多维度服务支持。
            </p>
          </li>
          <li class="ec-home-service-item">
            <img
              class="ec-home-service-item-icon"
              src="@/assets/images/home/product-icon05.png"
              alt=""
            />
            <h4 class="ec-home-service-item-title">员工体检</h4>
            <p class="ec-home-service-item-article">
              企业体检平台定制，报销全国覆盖。 兼容原有供应商，套餐自由选择。
              支持员工加项/家属参检。 微信/邮件/短信/书面多种通知方式。
              企业内部宣导。
            </p>
          </li>
          <li class="ec-home-service-item">
            <img
              class="ec-home-service-item-icon"
              src="@/assets/images/home/product-icon06.png"
              alt=""
            />
            <h4 class="ec-home-service-item-title">企业内购</h4>
            <p class="ec-home-service-item-article">
              特别为企业打造提高员工福利体验，企业特权专享内部价，提高员工满意度和幸福感，多维度提升企业人才竞争优势。
            </p>
          </li>
        </ul>
      </div>
    </section>
    <section class="ec-home-app">
      <div class="ec-container">
        <div class="ec-home-app-head">
          <h3 class="ec-home-app-head-title">
            <i class="ec-icon ec-icon-port-titleicon"></i>
            <span>可搭载于多个平台</span>
          </h3>
          <h4 class="ec-home-app-head-title sub">成熟的技术接入方案</h4>
        </div>
        <img
          class="ec-home-app-img"
          src="@/assets/images/home/port-ph.png"
          alt=""
        />
        <ul class="ec-home-app-feature">
          <li
            class="ec-home-app-feature-item"
            v-for="appFeat in appFeature"
            :key="appFeat.key"
          >
            <i
              class="ec-home-app-feature-icon ec-icon"
              :class="appFeat.icon"
            ></i>
            <div class="ec-home-app-feature-content">
              <h4 class="ec-home-app-feature-title">{{ appFeat.title }}</h4>
              <p class="ec-home-app-feature-desc">{{ appFeat.text }}</p>
            </div>
          </li>
        </ul>
      </div>
    </section>
    <section class="ec-home-partner">
      <div class="ec-container">
        <h2 class="ec-home-partner-title">他们都在用</h2>
        <img
          class="ec-home-partner-img"
          src="@/assets/images/home/cooperaLogo.png"
          alt=""
        />
      </div>
    </section>
  </div>
</template>

<script>
import EcButton from "@/components/Button";
import Tabs from "./tabs/Index.vue";
import { heightToTop } from "@/utils";

export default {
  name: "HomeIndex",
  components: { EcButton, Tabs },
  data() {
    return {
      appFeature: [
        {
          key: "1",
          title: "OA接入",
          icon: "ec-icon-port-icon01",
          text: "OA系统包含流程，知识，门户，移动办公，微信办公等OA系统应用",
        },
        {
          key: "2",
          title: "企业号接入",
          icon: "ec-icon-port-icon02",
          text: "企业微信是专注企业的沟通工具，有着与微信一致的沟通体验，公用账号体系。",
        },
        {
          key: "3",
          title: "服务号接入",
          icon: "ec-icon-port-icon03",
          text: "业务支持嵌入企业服务号，实现单击登录，企业集中管理无缝接收关怀消息。",
        },
        {
          key: "4",
          title: "阿里钉钉接入",
          icon: "ec-icon-port-icon04",
          text: "专为中小企业打造的企业快速沟通、高效协作、知识管理的及时通讯工具。",
        },
        {
          key: "5",
          title: "企业自由APP",
          icon: "ec-icon-port-icon05",
          text: "很多企业已经拥有自己的员工生活服务平台，点滴关怀已经掌握了成熟的自由平台的通用接入方案。",
        },
      ],
    };
  },
  methods: {
    onFree() {
      const target = document.getElementById("ecFooter");
      document.documentElement.scrollTop = heightToTop(target);
    },
  },
};
</script>

<style lang="less" scoped>
.ec-home {
  &-header {
    background: url("@/assets/images/home/homeBg.png") no-repeat left top /
      cover;
    height: 765px;
    min-width: max-content;

    .ec-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    &-left {
    }
    &-title {
      color: #282e65;
      font-size: 38px;
      font-weight: 500;
      margin-top: 227px;

      &.sub {
        font-size: 20px;
        font-weight: 400;
        margin-top: 20px;

        p {
          margin-top: 20px;
        }
      }
    }
    &-btn {
      width: 145px;
      height: 46px;
      border-radius: 23px;
      margin-top: 70px;
    }
  }
  &-features {
    margin-top: 50px;

    &-card {
      height: 143px;
      background: #ffeee6;
      border: 1px solid #ffd5b7;
      border-radius: 11px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 130px;

      &-item {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
      }
      &-icon {
        display: block;
        width: 52px;
        height: 48px;
      }
      &-text {
        font-size: 22px;
        font-weight: 400;
        color: #0b1d30;
        margin-top: 10px;
      }
    }
  }
  &-content {
    margin-top: 98px;

    &-title {
      text-align: center;
      font-size: 32px;
      font-weight: 400;
      color: #242933;

      &.sub {
        font-size: 16px;
        margin-top: 21px;
      }
    }
  }
  &-service {
    margin-top: 98px;

    &-title {
      text-align: center;
      font-size: 32px;
      font-weight: 400;
      color: #242933;

      &.sub {
        font-size: 16px;
        margin-top: 21px;
      }
    }
    &-list {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      gap: 25px;
      margin-top: 64px;
    }
    &-item {
      width: 402px;
      height: 366px;
      background: #ffeee6;
      border-radius: 8px;
      padding: 42px 50px 0;
      transition: all 0.3s linear;

      &:hover {
        transform: translate(2px, -6px);
        box-shadow: 0 2px 16px 0 rgba(104, 119, 174, 0.2);
      }

      &-icon {
        display: block;
        border: 0;
        text-align: center;
        width: 41px;
        height: 41px;
        margin: 0 auto;
      }
      &-title {
        font-size: 22px;
        font-weight: 400;
        color: #3e4249;
        line-height: 32px;
        margin-top: 17px;
        text-align: center;
        cursor: default;
      }
      &-article {
        margin-top: 22px;
        font-size: 14px;
        font-weight: 400;
        color: #3e4249;
        line-height: 32px;
        cursor: default;
      }
    }
  }
  &-app {
    width: 100%;
    background: url("@/assets/images/home/portBg.png") no-repeat left top /
      cover;
    position: relative;
    margin-top: 109px;
    min-width: max-content;

    .ec-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 94px;
      padding-bottom: 76px;
    }

    &-head {
      &-title {
        font-size: 32px;
        font-weight: bold;
        color: #ffffff;
        .ec-icon {
          font-size: 38px;
          color: #ffffff;
        }
        span {
          margin-left: 5px;
        }
        &.sub {
          font-size: 28px;
          font-weight: 400;
          padding-left: 45px;
        }
      }
    }
    &-img {
      width: 382px;
      flex-shrink: 0;
      flex-grow: 0;
      transform: translate(-10px, 44px);
    }
    &-feature {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 15px;

      &-item {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 21px;
      }
      &-icon {
        font-size: 49px;
        flex-shrink: 0;
        flex-grow: 0;
        transform: translateY(-10px);
      }
      &-content {
        flex-shrink: 0;
        flex-grow: 0;
        width: 345px;
      }
      &-title {
        font-size: 20px;
        font-weight: bold;
        color: #ffffff;
      }
      &-desc {
        font-size: 14px;
        color: #ffffff;
        margin-top: 10px;
      }
    }
  }
  &-partner {
    margin-top: 54px;

    &-title {
      font-size: 32px;
      font-weight: 400;
      color: #242933;
      text-align: center;
    }
    &-img {
      margin-top: 60px;
      min-height: 342px;
    }
  }
}
</style>
