import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/home/Index.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/news",
    name: "news",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/news/Index.vue"),
    children: [
      {
        path: "",
        name: "newsList",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/news/List.vue"),
      },
      {
        path: "detail/:id",
        name: "newsDetail",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/news/Detail.vue"),
      },
    ],
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/about/Index.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
