export const FETCH_CONF = new Proxy(
  {
    Session: null,
  },
  {
    get(target, p) {
      let value = Reflect.get(target, p);
      if (!target[p]) {
        value = window.localStorage.getItem(`EC_BENEFITS_${p}`);
      }
      return value;
    },
    set(target, p, value) {
      window.localStorage.setItem(`EC_BENEFITS_${p}`, value);
      return Reflect.set(target, p, value);
    },
  }
);
