<script setup>
import { computed, ref } from "vue";
import Tab from "./Tab.vue";
import TabImg1 from "@/assets/images/home/customized-tab1-img.png";
import TabImg2 from "@/assets/images/home/customized-tab2-img.png";
import TabImg3 from "@/assets/images/home/customized-tab3-img.png";
import TabImg4 from "@/assets/images/home/customized-tab4-img.png";
import TabIcon1 from "@/assets/images/home/customized-ico01b.png";
import TabIcon2 from "@/assets/images/home/customized-ico02b.png";
import TabIcon3 from "@/assets/images/home/customized-ico03b.png";
import TabIcon4 from "@/assets/images/home/customized-ico04b.png";
import TabIcon1Active from "@/assets/images/home/customized-ico01a.png";
import TabIcon2Active from "@/assets/images/home/customized-ico02a.png";
import TabIcon3Active from "@/assets/images/home/customized-ico03a.png";
import TabIcon4Active from "@/assets/images/home/customized-ico04a.png";

const list = ref([
  {
    key: "1",
    title: "创意内宣设计",
    icon: TabIcon1,
    iconActive: TabIcon1Active,
    img: TabImg1,
    badges: ["企业内宣海报", "H5设计定制", "福利平台", "企业标识", "页面设计"],
    article:
      "帮助企业提升文化宣导，实现企业专属视觉传达，提升员工认同感和满意度应用自由配置",
  },
  {
    key: "2",
    title: "爆款好物低价抢购",
    icon: TabIcon2,
    iconActive: TabIcon2Active,
    img: TabImg2,
    badges: ["低价好物", "爆款", "搜索好物", "回馈大家"],
    article:
      "每当一款爆款好物风靡的时候，我们总希望能够 在第一时间得到它，在益点关爱则有一群小编为大家搜罗市场上最火爆的好物，并且以全网最底价来回馈大家。",
  },
  {
    key: "3",
    title: "福利全案设计",
    icon: TabIcon3,
    iconActive: TabIcon3Active,
    img: TabImg3,
    badges: ["企业战略", "需求调研", "风险评估", "运营保障"],
    article:
      "结合企业战略和规划进行全面需求调研、 基准化分析及风险评估，精准制定专属福利方案，并提供持续运营保障。",
  },
  {
    key: "4",
    title: "新品独享预售试用",
    icon: TabIcon4,
    iconActive: TabIcon4Active,
    img: TabImg4,
    badges: ["低价好物", "品牌", "独家试用", "多种福利"],
    article:
      "不仅仅提供低价的商品，我们还提供品牌独家的新品试用，提前预售等多种福利类型。",
  },
]);

const active = ref(list.value[0].key);
const current = computed(() =>
  list.value.find((it) => {
    return it.key === active.value;
  })
);

const onTab = (tab) => {
  active.value = tab.key;
};
</script>

<template>
  <div class="ec-home-tabs">
    <div class="ec-container">
      <ul class="ec-home-tabs-navs">
        <li
          v-for="tab in list"
          :key="tab.key"
          class="ec-home-tabs-nav"
          :class="active === tab.key ? 'active' : ''"
          @click="onTab(tab)"
        >
          <img
            :src="active === tab.key ? tab.iconActive : tab.icon"
            alt=""
            class="ec-home-tabs-nav-icon"
          />
          <span>{{ tab.title }}</span>
        </li>
      </ul>
      <div class="ec-home-tabs-content">
        <Tab :tab="current" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.ec-home-tabs {
  margin-top: 93px;

  .ec-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 48px;
  }

  &-navs {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;

    li {
      width: 342px;
      height: 103px;
      border-radius: 4px;
      border: 1px solid #ffffff;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 32px;
      transition: all 0.3s linear;
      cursor: pointer;

      &:hover,
      &.active {
        background: #ffeee6;
        border-color: #ffd5b7;
      }

      .ec-home-tabs-nav-icon {
        display: block;
        width: 44px;
        height: 44px;
        flex-shrink: 0;
        flex-grow: 0;
      }
      span {
        font-size: 22px;
        font-weight: 400;
        color: #242933;
        margin-left: 40px;
      }
    }
  }
}
</style>
